<template>

  <div class=""> 

    <h2 class="divider-sec">
      {{ title }} 
    </h2>

    <div class="hybrid_items">

      <div class="hybrid_box" v-for="(opt,ind) in list">
        <div v-html="opt"></div>
      </div>

    </div>

  </div>

</template>

<script>
 

export default {   
  props: [
    'title',
    'list'
  ],
  data () {
    return {            
    }
  },
  created() {    
    // this.query = this.$route.query.q;    
  },
  methods: {
     
  }
}
</script>

<style >


/* HYBRIDS */
 
.hybrid_items{
  display: flex;
  flex-wrap: wrap;
}

.hybrid_box{
    margin-bottom: 20px;
}
.hybrid_box div *{
  margin-right: 5px;
}
.hybrid_box .scob{

    padding: 5px 5px;
    border-radius: 30px;
    display: inline-block;
    border: 1px #DADADA solid;
}
.hybrid_box .f_scob{

    padding: 5px 5px;
    border-radius: 30px;
    display: inline-block;
    border: 1px #DADADA dashed;
}
.hybrid_box .link{
    padding: 5px 16px;
    background-color: #70BD6A;
    color: white;
    border-radius: 20px;
    display: inline-block;
}
.hybrid_box .empty_link{
    padding: 5px 16px;
    border-radius: 20px;
    display: inline-block;
    border: 1px #DADADA solid;
}
@media (max-width: 768px) {
  .hybrid_box .empty_link{
    margin-bottom: 10px;
  }
}


</style>
