<template>

  <h2 class="divider-sec">
    {{ title }} 
  </h2>

  <div class="tree-container">
    <div class="tree-container-in">
      <BrandFamilyTreeHorizontalItem
        v-for="root in roots"
        :key="root.id"
        :item="root"
        :brothers="roots.length"
        :list="list"
        :lvl="root.lvl"
        :column="0"
      />
    </div>
  </div>
  
</template>

<script setup>

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  list: {
    type: Array,
    default: () => []
  }
})

const roots = ref(props.list.filter(item => !item.parent_id))

</script>

<style>
.tree-container {
  overflow-x: auto;
  width: 100%;
  padding: 0 2rem  2rem  2rem;
}
.tree-container-in {
  width: fit-content;
}
</style>
