<template>
  <div :class="{'tree': true, 'single': brothers == 1, 'bottom-line': children.length, 'top-line': lvl > 0}">
    <div class="tree-row">
      <NuxtLink v-if="props.item.seed_url" :to="props.item.seed_url" class="nm lnk">
        {{ props.item.name }}
      </NuxtLink>
      <div v-else class="nm">
        {{ props.item.name }}
      </div>
    </div>
    <div class="tree-content">
      <BrandFamilyTreeHorizontalItem
        v-for="(child, index) in children"
        :key="child.id"
        :item="child"
        :brothers="children.length"
        :list="props.list"
        :lvl="props.lvl + 1"
        :column="props.column + index"
      />
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  brothers: {
    type: Number,
    default: 0
  },
  list: {
    type: Array,
    default: () => []
  },
  lvl: {
    type: Number,
    default: 0
  },
  column: {
    type: Number,
    default: 0
  }
})


const children = ref(props.list.filter(i => props.item.lvl < i.lvl && i.parent_id == props.item.id))

</script>

<style scoped>
.tree {
  display: flex;
  /* align-items: center;
  justify-items: flex-start; */
  flex-direction: column;
  margin-bottom: auto;
  text-align: center;
  position: relative;
  margin-top: 20px;
  min-width: 130px;
  width: min-content;
}

.tree:not(.single)::before {
  content: ' ';
  position: absolute;
  top: -10px;
  width: 100%;
  height: 1px;
  background-color: #70BD6A;
}
.tree:first-child::before {
  left: 50%;
  width: 50%;
}
.tree:last-child::before {
  right: 50%;
  width: 50%;
}
.tree-content{
  display: flex;
  align-items: center;
  justify-items: center;
}

.nm{
  padding: 5px 16px;
  background-color: #70BD6A;
  color: white;
  border-radius: 8px;
  display: inline-block;
  margin:  0 10px;
  position: relative;

  
  /* position: sticky;
    left: -100px;
    right: -100px; */
}
.nm.lnk{
  /* font-weight: bold; */
  text-decoration: underline;

}
.tree.top-line > .tree-row > .nm::before {
  content: ' ';
  position: absolute;
  top: -10px;
  width: 1px;
  height: 10px;
  background-color: #70BD6A;
  left: 50%;
}
.tree.bottom-line > .tree-row > .nm::after {
  content: ' ';
  position: absolute;
  bottom: -10px;
  width: 1px;
  height: 10px;
  background-color: #70BD6A;
  left: 50%;
}

.tree-row {

}
</style>
